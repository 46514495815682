import Login from "./pages/login/Login";

function App() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default App;
